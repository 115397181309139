import React from 'react';
import { MainLayout } from '../../layouts'
import {Footer, HeroSection, LineDotContainer, Paragraph, Title} from "../../components";

import  styles from './styles.module.css'

export const InformationSecurityPolicy = () => {
    return (
        <MainLayout>
            <HeroSection className={styles.customHero }>
                <Title colour="white" weight="bold" size="large">
                    Information Security Policy
                </Title>
            </HeroSection>
            <div className={ styles.innerContent}>
                <LineDotContainer>
                    <Title size="small">Calibrate Consulting information security policy: committed to quality and excellence – #theCalibrateway</Title>

                    <Paragraph>Since February 2018 to guide our firm, Calibrate has formally adopted and been driven by four key business objectives focused on our clients (#grow), our people (#teamcalibrate), our culture (#behuman) and continuous improvement (#simplify).</Paragraph>
                    <Paragraph>Underpinning our delivery capability and the trust between our clients and our people is Information Security. Including client confidentiality and safeguarding personal data, we aim to build information security into all our activities. Information security starts with our people. We set policies to support the key principles of confidentiality, integrity and availability, we provide education and information to encourage Information Security as a natural part of everyday activity, and provide systems to #simplify the requirement for vigilance and adherence to standards. We control access to data and take steps to monitor activity and report issues and risks.</Paragraph>
                    <Paragraph>Calibrate Consulting is committed to providing clients with an exceptionally high quality service and delivery framework to meet their requirements on time and on budget, with high levels of client satisfaction. This necessarily includes high quality Information Security.</Paragraph>
                    <Paragraph>
                        Calibrate Consulting provides its clients with specialist consultancy services designed to help them digitally connect and transform their businesses. We solve complex business problems and advise each client on their unique digital transformation journey. We support our clients to design the most appropriate tailored solutions that help their businesses to thrive. We operate across industries and business functions and provide end to end services including management consulting, systems integration, on-premise, cloud and hybrid managed services and resourcing.
                    </Paragraph>
                    <Paragraph>Calibrate Consulting operates an Information Security Management System which it continuously reviews for improvement. The Leadership Team and every member of #teamcalibrate are jointly responsible for Information Security. Our business policy is to only work with suppliers and subcontractors who share our commitment to quality and support our aims.</Paragraph>
                    <Paragraph>Specific objectives of the Information Security policy are:</Paragraph>
                    <Paragraph>
                        To deliver secure, reliable cloud services for users (and other interested parties) and the means of providing assurance the platforms are fit for their purpose of sharing and working with sensitive information.
                    </Paragraph>
                    <Paragraph>To ensure that all employees, contractors and third party users are aware of information security threats and concerns, their responsibilities and liabilities, and are equipped to support organizational security policy in the course of their normal work, and to reduce the risk of human error.
                    </Paragraph>
                    <Paragraph>o provide visibility to management that our systems are working properly and that access to information is appropriately controlled, including regular assurance that information assets are properly protected from internal and external threats.
                    </Paragraph>
                    <Paragraph>To enable us to fulfil and be seen to fulfil our data safeguarding obligations to our clients, employees, contractors and other interested parties.
                    </Paragraph>
                    <Paragraph>Calibrate Consulting is committed to exceeding our clients’ expectations by the use of Information Security Procedures which will meet or exceed the requirements of ISO 27001.
                    </Paragraph>

                    <Title size="small">Scope of the Policy</Title>
                    <Paragraph>
                        The scope of this policy relates to use of the databases and computer systems operated by the company at its office in The Spice Building, 8 Devonshire Square, EC2M 4YJ, in pursuit of the company’s business of providing specialist consultancy services designed to help our clients digitally connect and transform their businesses.
                    </Paragraph>

                </LineDotContainer>

            </div>
            <Footer/>
        </MainLayout>
    )
}